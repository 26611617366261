// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-connectmevoice-js": () => import("/opt/build/repo/src/pages/connectmevoice.js" /* webpackChunkName: "component---src-pages-connectmevoice-js" */),
  "component---src-pages-design-skills-js": () => import("/opt/build/repo/src/pages/design-skills.js" /* webpackChunkName: "component---src-pages-design-skills-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privo-js": () => import("/opt/build/repo/src/pages/privo.js" /* webpackChunkName: "component---src-pages-privo-js" */),
  "component---src-pages-teamconnect-js": () => import("/opt/build/repo/src/pages/teamconnect.js" /* webpackChunkName: "component---src-pages-teamconnect-js" */),
  "component---src-pages-tech-skills-js": () => import("/opt/build/repo/src/pages/tech-skills.js" /* webpackChunkName: "component---src-pages-tech-skills-js" */),
  "component---src-pages-ux-process-js": () => import("/opt/build/repo/src/pages/ux-process.js" /* webpackChunkName: "component---src-pages-ux-process-js" */)
}

